// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: rgba(230, 239, 109, 0.9);
  padding: 1.1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 87%;
  margin: 1rem auto;
  box-shadow: 0 4px 55px rgba(230, 239, 109, 0.9);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.logo-link {
  display: inline-block;
  margin-left: 1.2rem; /* Adjust right margin to move the logo farther right */
  margin-top: 10px; /* Adjust top margin to move the logo higher up */
}

.logo {
  max-height: 50px;
  transition: transform 0.5s;
}

.logo:hover{
  transform: scale(1.2);
}

.nav-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0;
}

.nav-links li {
  margin: 0 1rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
  display: flex;
  align-items: center;
}

.nav-links a:hover {
  color: #1abc9c;
}

.external-link-image {
  width: 60px;
  height: auto;
  border-radius: 50%;
  margin-right: 2rem;
  transition: transform 0.3s;
}

.external-link-image:hover {
  transform: scale(1.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU;EACV,iBAAiB;EACjB,+CAA+C;EAC/C,kCAA0B;UAA1B,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,mBAAmB,EAAE,uDAAuD;EAC5E,gBAAgB,EAAE,iDAAiD;AACrE;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".navbar {\n  background-color: rgba(230, 239, 109, 0.9);\n  padding: 1.1rem;\n  border-radius: 5px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 87%;\n  margin: 1rem auto;\n  box-shadow: 0 4px 55px rgba(230, 239, 109, 0.9);\n  backdrop-filter: blur(5px);\n}\n\n.logo-link {\n  display: inline-block;\n  margin-left: 1.2rem; /* Adjust right margin to move the logo farther right */\n  margin-top: 10px; /* Adjust top margin to move the logo higher up */\n}\n\n.logo {\n  max-height: 50px;\n  transition: transform 0.5s;\n}\n\n.logo:hover{\n  transform: scale(1.2);\n}\n\n.nav-links {\n  list-style-type: none;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  margin: 0;\n}\n\n.nav-links li {\n  margin: 0 1rem;\n}\n\n.nav-links a {\n  color: white;\n  text-decoration: none;\n  font-weight: 500;\n  transition: color 0.3s;\n  display: flex;\n  align-items: center;\n}\n\n.nav-links a:hover {\n  color: #1abc9c;\n}\n\n.external-link-image {\n  width: 60px;\n  height: auto;\n  border-radius: 50%;\n  margin-right: 2rem;\n  transition: transform 0.3s;\n}\n\n.external-link-image:hover {\n  transform: scale(1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
