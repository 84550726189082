import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Ensure this is correctly linked in your component
import pumpImage from './Pump.png';
import dexscreenerImage from './Dexscreener.png';
import logo from './logo.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="logo-link">
        <img src={logo} alt="Logo" className="logo" />
      </Link>
      <ul className="nav-links">
        <li>
          <a href="https://pump.fun/EcHKj9HotpDnPDhMzCYVEYvAQTnuTdf2U29kXaVcpump" target="_blank" rel="noopener noreferrer">
            <img src={pumpImage} alt="External Link 1" className="external-link-image" />
          </a>
        </li>
        <li>
          <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer">
            <img src={dexscreenerImage} alt="Desscreener Link" className="external-link-image" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
