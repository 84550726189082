import React from 'react';
import './Home.css';
import image from '../coinlogo.png';
import telegramLogo from '../telegram.png';
import twitterLogo from '../twitter.png';

const Home = () => {
  return (
    <div className="home-container">
          <img src={image} alt="Shopping Cat" className="coinlogo" />
      <div className="ca-box">
        <h3>EcHKj9HotpDnPDhMzCYVEYvAQTnuTdf2U29kXaVcpump</h3>
      </div>
      <div className="button-container">
        <a href="https://t.me/+bEltd6VZke1iYzE0" target="_blank" rel="noopener noreferrer" className="button">
          <img src={telegramLogo} alt="Telegram" className="button-logo" />
        </a>
        <a href="https://x.com/ShoppingCatSol" target="_blank" rel="noopener noreferrer" className="button">
          <img src={twitterLogo} alt="Twitter" className="button-logo" />
        </a>
      </div>
    </div>
  );
};

export default Home;
